import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import { DescriptionBlock2 } from '../../components/description-block'
import { PreviewBlock2 } from '../../components/preview-block'
import {
  ModuleSection2,
  ModuleSectionItem2,
} from '../../components/module-section-2'
import ImageTabs from '../../components/Common/ImageTabs'
import DashboardModuleBlock from '../../components/DashboardModuleBlock'
import RequestDemo from '../../components/RequestDemo'
import ContactForm, { IContactModalState } from '../../components/contact-form'
import { Button } from '../../components/Common/Button'
import ModalDownloadFile from '../../components/Common/ModalDownloadFile'

import { isBrowser, toLink } from '../../utils'
import {
  downloadPriceListLink,
  signalMainDownloadPresentationLink,
  toolsLink,
  toolsLinkAe,
} from '../../data'
import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'

import NavisIcon from '../../svg/navis-icon'
import RevitIcon from '../../svg/revit-icon'
import SgnlImageSeo from '../../assets/images/seo/sgnl/tools.png'
import SgnxImageSeo from '../../assets/images/seo/sgnx/tools.png'

import * as s from './Tools.module.scss'

const ToolsModulePage: React.FC = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const { region, location } = React.useContext(GlobalContext)
  const [modal, setModal] = React.useState<IContactModalState>()
  const [presentationModal, setPresentationModal] = React.useState(false)
  const locationState = location?.state

  useEffect(() => {
    if (isBrowser()) {
      if (locationState?.presentationModalOpened) {
        setPresentationModal(true)
        locationState.presentationModalOpened = false
      }
    }
  }, [locationState, t])

  return (
    <>
      <SEO
        title={t('seo_title_tools')}
        description={t('seo_description_tools')}
        image={region === AppRegionEnum.Ru ? SgnlImageSeo : SgnxImageSeo}
        keywords={region === AppRegionEnum.Us ? t('keywords_tools') : undefined}
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        imageMobile={images.bannerMobile.childImageSharp.gatsbyImageData}
        title={`${t('Signal')} Tools`}
        colorShadow="dark"
      />
      <Section>
        <DescriptionBlock2
          title={t('Tools title')}
          description={t('Tools description')}
          highlightTitleWordsByIdx={region === AppRegionEnum.Ru ? [2] : [3]}
        >
          <div className={s.icons}>
            <NavisIcon className={s.icon} />
            <RevitIcon className={s.icon} />
          </div>
        </DescriptionBlock2>
        <PreviewBlock2
          src={images[`preview_${region}`].childImageSharp.gatsbyImageData}
          srcClassName={s.preview}
          srcTitle={t('_tools_image_title')}
          srcPosition="center"
          btnData={{
            text: t('Download'),
            position: 'right',
            onClick: () =>
              toLink(region === AppRegionEnum.Ru ? toolsLink : toolsLinkAe),
          }}
        />
      </Section>
      <ModuleSection2
        title={
          region === AppRegionEnum.Ru
            ? t('Module features')
            : t('Module advantages')
        }
      >
        <ModuleSectionItem2
          title={t('_tools__subtitle')}
          description={t('_tools__lead')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_20' : 'author_10',
            position: t('BIM-manager'),
            text: t('_tools__quote'),
          }}
          columnRightChildren={
            <GatsbyImage
              image={
                images[`checker_${region}`].childImageSharp.gatsbyImageData
              }
              alt={t('_tools_image_alt1')}
              title={t('_tools_image_title1')}
              objectFit="contain"
              imgStyle={{ maxHeight: region === AppRegionEnum.Ru ? 420 : 318 }}
            />
          }
        />
        <ModuleSectionItem2
          title={t('_tools__subtitle2')}
          positionTitle="right"
          description={t('_tools__lead2')}
          quote={{
            author: 'author_13',
            position: t('BIM-coordinator'),
            text: t('_tools__quote2'),
          }}
          columnRightChildren={
            <GatsbyImage
              image={images.model.childImageSharp.gatsbyImageData}
              alt={t('_tools_image_alt3')}
              title={t('_tools_image_title3')}
              objectFit="contain"
              imgStyle={{ maxHeight: 294 }}
            />
          }
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_tools__subtitle3')}
          description={t('_tools__lead3')}
          quote={{
            author: 'author_13',
            position: t('BIM-coordinator'),
            text: t('_tools__quote3'),
          }}
          columnRightChildren={
            <ImageTabs
              images={[
                {
                  image: images.rvt.childImageSharp.gatsbyImageData,
                  tabName: '.rvt',
                },
                {
                  image: images.dwg.childImageSharp.gatsbyImageData,
                  tabName: '.dwg',
                },
                {
                  image: images.ifc.childImageSharp.gatsbyImageData,
                  tabName: '.ifc',
                },
              ]}
              imageAlt={t('_bim_image_alt')}
              imageTitle={t('_bim_image_title')}
            />
          }
        />
        <ModuleSectionItem2
          title={t('_tools__subtitle4')}
          positionTitle="right"
          description={t('_tools__lead4')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_24' : 'author_14',
            position: t('Digital engineer'),
            text: t('_tools__quote4'),
          }}
          columnRightChildren={
            <GatsbyImage
              image={
                images[`documents_${region}`].childImageSharp.gatsbyImageData
              }
              alt={t('_tools_image_alt4')}
              title={t('_tools_image_title4')}
            />
          }
          reverseElements
        />
        <ModuleSectionItem2
          title={t('_tools__subtitle5')}
          description={t('_tools__lead5')}
          quote={{
            author: region === AppRegionEnum.Ru ? 'author_24' : 'author_14',
            position: t('Digital engineer'),
            text: t('_tools__quote5'),
          }}
          image={images[`image5_${region}`].childImageSharp.gatsbyImageData}
        />
        <DashboardModuleBlock reverseElements />
      </ModuleSection2>
      {region === AppRegionEnum.Ru ? (
        <RequestDemo
          title={t('Trial period')}
          imageData={{
            image: images.demo_ru.childImageSharp.gatsbyImageData,
            title: `TOOLS ${t('Demo')}`,
          }}
          appData={{
            text: t('Try Signal Tools for free'),
            btnText: t('Request a demo_1'),
            onClick: () =>
              setModal({
                title: t('Request a demo of Signal'),
                requestType: 'Demo',
              }),
            btnColor: 'white',
          }}
          moduleData={{
            text: t('Tools demo'),
            btnText: t('Download price list'),
            btnColor: 'transparent',
            onClick: () => toLink(downloadPriceListLink),
          }}
          buttonsElements={
            <Button
              size="large"
              color="transparent"
              onClick={() =>
                setModal({
                  title: t('Request testing'),
                  requestType: 'Trial',
                })
              }
            >
              {t('Request testing')}
            </Button>
          }
        />
      ) : (
        <RequestDemo
          title={t('Request a demo_1')}
          imageData={{
            image: images.demo_us.childImageSharp.gatsbyImageData,
            title: `TOOLS ${t('Demo')}`,
          }}
          appData={{
            text: t('Try Signal Tools for free'),
            btnText: t('Download'),
            btnColor: 'white',
            onClick: () => toLink(toolsLinkAe),
          }}
          moduleData={{
            text: t('Tools demo'),
            btnText: t('Request a demo_1'),
            onClick: () =>
              setModal({
                title: t('Request a demo of Signal'),
                requestType: 'Demo',
              }),
            btnColor: 'transparent',
          }}
        />
      )}
      {modal && (
        <ContactForm
          onClose={() => setModal(undefined)}
          title={modal.title}
          requestType={modal.requestType}
        />
      )}
      {region === AppRegionEnum.Ru && presentationModal && (
        <ModalDownloadFile
          fileUrl={signalMainDownloadPresentationLink}
          title={t('Download presentation')}
          image={images.presentatio_preview.childImageSharp.gatsbyImageData}
          imageTitle="Стандарт цифрового строительства"
          onClose={() => setPresentationModal(false)}
        />
      )}
    </>
  )
}

export default ToolsModulePage

const imagesQuery = graphql`
  query {
    banner: file(relativePath: { eq: "tools/banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    bannerMobile: file(relativePath: { eq: "tools/banner-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    preview_ru: file(relativePath: { eq: "tools/image-preview.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    preview_us: file(relativePath: { eq: "tools/image-preview-en.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    checker_ru: file(relativePath: { eq: "tools/checker.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 536
          height: 420
          placeholder: BLURRED
        )
      }
    }
    checker_us: file(relativePath: { eq: "tools/checker-en.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    model: file(relativePath: { eq: "tools/image-model.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    rvt: file(relativePath: { eq: "tools/rvt.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
      }
    }
    dwg: file(relativePath: { eq: "dwg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 355, placeholder: BLURRED)
      }
    }
    ifc: file(relativePath: { eq: "ifc.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: BLURRED)
      }
    }
    documents_ru: file(relativePath: { eq: "tools/documents.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    documents_us: file(relativePath: { eq: "tools/documents-en.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image5_ru: file(relativePath: { eq: "tools/image-5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    image5_us: file(relativePath: { eq: "tools/image-en-5.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    demo_ru: file(relativePath: { eq: "tools/signal-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 536, placeholder: BLURRED)
      }
    }
    demo_us: file(relativePath: { eq: "tools/signax-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 764, placeholder: BLURRED)
      }
    }
    presentatio_preview: file(
      relativePath: { eq: "signal-preview-presentation.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 552, placeholder: BLURRED)
      }
    }
  }
`
